import React from 'react';
import {Link} from 'react-router-dom';

/* ------------- FINNISH ----------------------*/ 

var finnish = {


    frontpage: {

        "text": "Esityksiä kaikkiin tilaisuuksiin!", 
        "text2": "Ohjelmaa kaikenikäisille!",
        "words": ["Esityksiä kaikkiin tilaisuuksiin!", "\"Ammattimaista, yleisöä huomioivaa ja viihdyttävää taituruutta.\"", "\"Kai Kuutamo on vakuuttavan karismaattinen esiintyjä.\""],
        "words2": ["Ohjelmaa kaikenikäisille!", "- Olli Lepänrinne / MagnumLive", "- Turun Sanomat"]

    },


    header: 

    <div id="headlinkwrap">

    <nav>

        <ul>
                <li><Link to="/who">KUKA</Link></li>
                <li><Link to="/services">PALVELUT</Link></li>
                <li><Link to="/shows">ESITYKSET</Link></li>
                <li><Link to="/calendar">KALENTERI</Link></li>
                <li><Link to="/media">KUVAT</Link></li>
                <li><Link to="/videos">VIDEOT</Link></li>
                <li><Link to="/yritys">YRITYKSILLE</Link></li>
                <li><Link to="/offer">TARJOUS</Link></li>
                <li><Link to="/contact">YHTEYS</Link></li>
        </ul>

    </nav>

    </div>,

    who:
    <div>
    <div className="tabletitlewrap">
    <h1 className="tabletitle">Jonglööri Kai Kuutamo</h1>
    </div>

     <div className="tableintro">
     <div className="tabletext">
     
     <p>Kai Kuutamo on palkittu esiintyvä taiteilija, jonka erikoisalaa ovat puhetyöt, komiikka ja jongleeraus. 
    Hän on korkeakoulutettu sirkustaiteilija, pitkän linjan yrittäjä ja myös Sirkuskauppa.com -verkkokaupan omistaja.</p>

    <p><i><font color="yellow">"Ammattimaista, yleisöä huomioivaa ja viihdyttävää taituruutta jonglööri Kai Kuutamolta."</font></i></p>
    <p><font color="yellow">- Olli Lepänrinne, Ohjelmamyyjä, MagnumLive</font></p>

     <p>Uransa aikana Kuutamo on opiskellut jongleerausta Lahdessa, Turussa sekä Kiovassa Ukrainassa. Hän on esiintynyt Englannissa, Saksassa, Belgiassa, Ranskassa, Ruotsissa, Eestissä, Thaimaassa ja Kambodzassa. Kuutamo on voittanut katuesiintymisen Suomen mestaruuden kahdesti, rikkonut jongleerauksen Suomen ennätyksen sekä pitänyt yllä kaksi vuotta ilmaista sirkuskoulua lapsille Kambodzassa (Circus Kampot).</p>

     <p><i><font color="yellow">"Sä teet hyvästä itsetunnosta taidetta!"</font></i></p>
    <p><font color="yellow">- Harri Lukander, Juontaja</font></p>

     <p>Nykyään hän työskentelee pääasiassa Suomessa esiintyen yksin tai lukuisten omien ryhmiensä kanssa. Esiintymispaikat vaihtelevat yritystilaisuuksista vanhainkoteihin sekä suurtapahtumista pieniin syntymäpäiviin. Kai Kuutamo & Sirkussirkus järjestää myös epäsäännöllisesti Multi Bueno -festivaalia Turussa.</p>

     <p><i><font color="yellow">"Olemme käyttäneet Kai Kuutamoa ja hänen sirkuspalveluitaan lukuisissa tapahtumissa ympäri Suomen. Olemme häneen ja hänen tarjoamiinsa sirkusohjelmiin enemmänkin kuin tyytyväisiä! Kaitsun tapa toimia on erinomaista, ja hän pystyy myös tuottamaan erittäin mainioita ja toteutuskelpoisia ideoita. Sitä paitsi Kaitsu on kaikin puolin mukava kaveri! Suosittelemme Kai Kuutamoa lämpimästi kaikille."</font></i></p>

     <p><font color="yellow">- Thomas Boucht, Tapahtumatuottaja, Suomen Kauppakeskustapahtumat</font></p>
<p></p>
    <p>Voit halutessasi tutustua minuun tarkemmin alla olevista linkeistä.</p>
<p></p>
     </div>
    

     </div>

     <center>
            <div className="white-line5"></div>
            </center>
    

     <div id="wholinks">
         <h2>Linkit</h2>
<p></p>
<p><a href="https://tip.sirkussirkus.com" rel="noopener noreferrer" target="cv">Kai Kuutamo Tippisivu</a></p>
<p><a href="https://www.sirkussirkus.com/portfolio" rel="noopener noreferrer" target="portfolio">Portfolio</a></p>
     <p><a href="https://www.sirkussirkus.com/cv.pdf" rel="noopener noreferrer" target="cv">Ansioluettelo</a></p>
     <p><a href="https://fi.wikipedia.org/wiki/Kai_Kuutamo" rel="noopener noreferrer" target="wiki">Kai Kuutamo Wikipediassa</a></p>
     <p><a href="https://kaikuutamo.sirkussirkus.com/articles" rel="noopener noreferrer">Lehtiartikkelit ja muu media</a></p>
     <p><a href="https://www.sirkussirkus.com/kauppa" rel="noopener noreferrer" target="blank">Sirkuskauppa.com</a></p>
     <p><a href="https://www.sirkussirkus.com/multibueno/index.html" rel="noopener noreferrer" target="blank">Multi Bueno monitaidefestivaali Turussa</a></p>
     <p><a href="https://www.facebook.com/circuskampot" rel="noopener noreferrer" target="blank">Circus Kampot sirkuskouluhanke Kambodzassa (2013-2016)</a></p>
     <p><a href="https://onlyfans.com/kaikuutamo" rel="noopener noreferrer" target="of">Kai Kuutamo OnlyFans</a></p>
     <p><a href="https://www.sirkussirkus.com/top5" rel="noopener noreferrer" target="of">TOP 5 -listat</a></p>
     <p></p>
     </div>
     </div>,


yritys: 
<div>

<div className="tabletitlewrap">

<div className="tabletitlewrap">
            <h1 style={{textAlign: "center"}} className="tabletitle">Yhteystiedot ja laskutus</h1>
            <div className="tableintro">
            <div className="tabletext contacttext">

            <center>
            <div className="white-line2"></div>
            </center>

            <p id="contacttitle2">Yrityksille</p>
            <p>kaikkea</p>
            <p>puh. 0407546182</p>
            <p>Turku</p>
            </div>
            </div>
            </div>
            </div>

            </div>,

services: 
     <div>

     <div className="tabletitlewrap">
      <h1 className="tabletitle">Palvelut</h1>
     </div>

     <div className="tableintro">
         <div className="tabletext">
             <p>Kai Kuutamo & Sirkussirkus tarjoaa kolmenlaisia palveluita:</p>
             <p>- Esitykset, ohjelmat ja juonnot</p>
             <p>- Jongleerauksen opetus ja työpajat</p>
             <p>- Sirkusvälineiden myynti</p>
         </div>
     </div>

     <div className="tableh2wrap">
        <img className="servicespic" src="pictures6/sirkusesitys.jpg" alt="Services"></img>
         <h2>Esitykset ja ohjelma</h2>
     </div>

     <div className="tableintro">
         <div className="tabletext">
             <p>Ohjelmaa etsiessäsi voit tutustua valmiisiin esityksiimme ESITYKSET-sivulla, täyttää tarjouspyyntölomakkeen tai ottaa suoraan yhteyttä ja kertoa tilausuudestasi. Muokkaamme esityksiä tilaajan kanssa yhteistyössä niin, että ohjelma parhaiten sopii tilaisuuden tai tapahtuman luonteeseen.</p>

             <p>Kai Kuutamon omasta repertuaarista löytyy erilaisia jongleerausohjelmia, tuliesityksiä ja puujalkahahmoja, jotka sopivat luontevasti myös juontotöihin. Juontaminen, luennointi ja seremoniamestarointi onnistuvat. Komiikkaa on väistämättä mukana lähes kaikissa ohjelmissa, mutta teemme lisäksi taiteellisia ja hillittyjä esityksiä yhteistyössä erilaisten muusikoiden kanssa. Välitämme myös muita taiteilijoita ja järjestämme isompiin tapahtumiin ohjelmakokonaisuuksia. Kerro minne, milloin sekä minkälaista ohjelmaa kaipaat ja pyydä rohkeasti tarjousta!</p>
             
             <p>Lopulliseen hintaan vaikuttavat esityksen sisältö, kesto ja matkakulut. Myös tilaaja vaikuttaa esitykseen. Yksityisille, yleishyödyllisille ja yritystilaisuuksille on kaikille omanlaisensa hinnoittelut. Kaupalliset yhteistyöt sovitaan tapauskohtaisesti. Tarjouksemme on laskutettava kiinteä hinta, joka sisältää sopimuksen mukaan kaikki kulut.</p>
             
         </div>
     </div>

     <div className="tableh3wrap">
     <img className="servicespic" src="pictures6/sirkustyopaja.jpg" alt="Services"></img>
         <h3>Jongleerausopetus ja jongleeraustyöpajat</h3>
     </div>

     <div className="tableintro">
         <div className="tabletext">
             <p>Tarjoamme jongleerausopetusta tilaisuuksiin, tapahtumiin, leireille ja kouluihin. Jongleerauksen ohjattu peruskurssi kestää sovitusti 45-90 minuuttia, ja sopii kaikenikäisille aikaisemmasta kokemuksesta riippumatta. Myös avoimet jongleeraustyöpajat ovat mahdollisia esimerkiksi koko päivän tapahtumiin tai vastaaviin tilaisuuksiin.</p>

             <p>Peruskurssi tai vastaava avoin jongleeraustyöpaja sopii niin yritystilaisuuksiin kuin esimerkiksi lastentapahtumiin. Tuomme välineet mukanamme sovitulle määrälle oppilaita, eikä osanottajilta vaadita muuta kuin mukavat vaatteet ja avoin mieli.</p> 
             
             <p>Tutustumiskurssilla välineenä ovat lähtökohtaisesti pallot, ja pyrkimyksenä on oppia kolmen pallon jongleeraus, mutta tilaajan toiveesta voimme keskittyä pallojen sijaan myös esimerkiksi renkaisiin, keiloihin, diaboloon tai Kendamaan. Avoimessa tai useamman tunnin työpajassa voidaan tutustua moneen eri välineeseen.</p> 
             
             <p>Lapsille suosituin työpajamalli on ollut avoin työpaja, jossa on paljon eri välineitä ja mukaan voi tulla ja myös lähteä missä kohtaa vain.</p>
             
             <p>Pystymme järjestämään myös tiiviskursseja edistyneemmille tarkasti rajatuista aiheista kuten esimerkiksi "Jongleerauksen perustekniikka yhdestä yhdeksään palloon", "Päävieritykset" (headrolls), "Diabolo-jongleeraus", "pinotekniikka" (stacking) tai "Vartaloheitot" (body throws).</p>
             
         </div>
     </div>

     <div className="tableh4wrap">
     <img className="servicespic" src="pictures6/sirkusvalineet.jpg" alt="Services"></img>
         <h4>Sirkusvälineet</h4>
     </div>

     <div className="tableintro">
         <div className="tabletext">
             <p>Kai Kuutamon Sirkussirkus Putiikki on myynyt sirkusvälineitä ammattilaisille ja harrastajille jo vuodesta 2005.</p>
             
             <p>Putiikki sijaitsi alkuaikoina Jarrumiehenkadulla Turussa. Se oli suljettuna vuodet 2011-2015 Aasiassa työskentelyn johdosta, ja se avattiin uudestaan verkkokauppana vuonna 2016 (www.sirkuskauppa.com). Marraskuussa 2017 uusi kivijalkakauppa avattiin osoitteeseen Lonttistentie 14, Turku. Maaliskuussa 2020 jouduimme siirtymään vuokrasopimuksen loputtua ylempiin kerroksiin samaan taloon, ja kivijalkakauppa muuttui varastomyymäläksi. Nykyään sijaitsemme kolmannessa kerroksessa osoitteessa Lonttistentie 12, Turku.</p>

             <p>Kaupasta saat parhaat jongleerausvälineet joka lähtöön. Lisäksi hyllystä löytyvät ilma-akrobatiavälineet, yksipyöräiset, rajattu määrä taikuustuotteita sekä paljon muita kiinnostavia myyntiartikkeleita. Verkkokaupan tilauksia toimitamme mahdollisuuksien mukaan päivittäin. Sirkuskaupan tarjontaan voit tutustua parhaiten osoitteessa:  </p>

             <p>
             <span id="shoplink"><a href="http://www.sirkussirkus.com/kauppa" target="blank">www.sirkuskauppa.com</a></span>.</p>
             
         </div>
     </div>

     </div>,

     mobilemenu: 
     
     <div className="licont">
     <li><Link to="/who">KUKA</Link></li>
     <li><Link to="/services">PALVELUT</Link></li>
     <li><Link to="/shows">ESITYKSET</Link></li>
     <li><Link to="/calendar">KALENTERI</Link></li>
     <li><Link to="/media">KUVAT</Link></li>
     <li><Link to="/videos">VIDEOT</Link></li>
     <li><Link to="/yritys">YRITYKSILLE</Link></li>
     <li><Link to="/offer">TARJOUS</Link></li>
     <li><Link to="/contact">YHTEYS</Link></li>
     </div>,

     videos: 

     <div>

            <div className="tabletitlewrap">

            
     
            </div>

            

            <div className="videos">

                <h2>Elävänä</h2>

                <p>Kai Kuutamo Show - Finlaysonin Alue, Tampere 2022</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="1" src="https://www.youtube.com/embed/g9MeGYo9_qw" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                <p>Pieniä hetkiä matkan varrelta esityksestä Kai Kuutamo Show 2017-2020.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="1" src="https://www.youtube.com/embed/mgXEuOOcmTY" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                <p>Kai Kuutamo ja Kalle Lehto. Jongleerausnumerot vuodelta 2015.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="2" src="https://www.youtube.com/embed/c0TV9iVjoyY" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>
                
            </div>


                <div className="videos">

                <h3>Leikattu ja liimattu</h3>

                <p>Merkkarit & Kai Kuutamo -kokoonpanon promootiovideo 2022.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="3" src="https://www.youtube.com/embed/N3H_NnM0mlw" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                <p>Circus Phare -teltassa Kambodzassa paikallisella otteella tuotettu promootiovideo 2012.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="3" src="https://www.youtube.com/embed/GwU39mFL7Vk" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                <p>Suomalaiset Patukat (Finnish Poles). Parhaat temput 2000-luvulta.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="4" src="https://www.youtube.com/embed/UofXrINtKrQ" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                
            </div>


                <div className="videos">

                <h4>Ennätyksiä</h4>

                <p>Virallinen Suomen ennätys 10 pallolla vuonna 2015 esitystilanteessa Raumalla.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="5" src="https://www.youtube.com/embed/kt_masR6uQA" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                <p>Epävirallinen viimeisin Suomen ennätys 10 pallolla vuonna 2016. Turun Sirkus.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="6" src="https://www.youtube.com/embed/Np22u_L19oc" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                
            </div>

                <div className="videos">

                <h4>Muuta</h4>

                <p>Kuvakooste järjestämästämme viidennestä Multi Bueno Festivaalista 2015. Musiikista vastaa Yakuzi Pato.</p>

                <div className="icont">
                <div className="icont2">
                <iframe className="video" title="7" src="https://www.youtube.com/embed/-P7RSNGgZxc" allowFullScreen></iframe>
                </div>
                </div>

                <center>
                <div className="white-line3"></div>
                </center>

                
            </div>

            <div id="morevideos">

            <p>Lisää videoita löydät Youtube-kanvalta <a id="kaiyoutube" href="https://www.youtube.com/user/kaikuutamo/" target="blank">kaikuutamo</a></p>

            </div>

                <center>
                <div className="white-line3"></div>
                </center>

            
            </div>,

            shows: {

                title1: <h2 className="showstitle">Sooloesitykset</h2>,
                title2: <h3 className="showstitle">Kumppanit ja ryhmät</h3>,

                old:
                <div>
                <div id="wholinks">
                    <p></p>
                    <h2>Muut esitykset</h2>

                <p><a href="http://www.leshanuleurs.com" rel="noopener noreferrer" target="blank">Les Hanuleurs - Maininki (nykysirkus)</a></p>
                <p><a href="http://www.leshanuleurs.com" rel="noopener noreferrer" target="blank">Les Hanuleurs - Valomatkaajat</a></p>

                <p><a href="http://www.sirkussirkus.com/lifebecomesus/" rel="noopener noreferrer" target="blank">Life Becomes Us (nykysirkus)</a></p>
                <p>Kivinen Kuutamo (nykysirkus)</p>
                <p><a href="http://www.sirkussirkus.com/herttakuninkaat/sisapihasirkus.php" rel="noopener noreferrer" target="blank">Herttakuninkaat - Sisäpihasirkus</a></p>
                <p><a href="http://www.sirkussirkus.com/joulupukki/" rel="noopener noreferrer" target="blank">Joulupukki ja Tempputonttu - Lasten Joululaulusirkusshow (kausiohjelma)</a></p>
                <p><a href="http://www.sirkussirkus.com/kkaa/" rel="noopener noreferrer" target="blank">Kai Kuutamo & Aimo Annos - Sirkus- ja musiikkispektaakkeli</a></p>
                </div>
                <p></p>
                <div id="wholinks">
                <p></p>
                <h2>Poistuneet ohjelmistosta</h2>

                <p><a href="http://www.sirkussirkus.com/northman/" rel="noopener noreferrer" target="blank">Pohjolan Äijä (nykysirkus)</a></p>
                <p><a href="http://www.sirkussirkus.com/specialdelivery/" rel="noopener noreferrer" target="blank">Special Delivery (nykysirkus)</a></p>
                <p><a href="http://www.sirkussirkus.com/tisch/" rel="noopener noreferrer" target="blank">Tisch (nykysirkus)</a></p>
                <p><a href="https://www.youtube.com/watch?v=GYFoVHWFQDA" rel="noopener noreferrer" target="blank">ALL IN (nykysirkus)</a></p>
                <p><a href="https://www.youtube.com/watch?v=4PS4xrsHZlg" rel="noopener noreferrer" target="blank">Kai Kuutamo & Miriam Tuominen</a></p>
                <p><a href="https://www.youtube.com/watch?v=egHroF9qGbw" rel="noopener noreferrer" target="blank">Soul on Fire</a></p>
                <p><a href="http://www.sirkussirkus.com/sirkuskunta/esitykset.html" rel="noopener noreferrer" target="blank">Taitureita ja Tunareita</a></p>
                <p><a href="http://www.sirkussirkus.com/sirkuskunta/esitykset.html" rel="noopener noreferrer" target="blank">Punaisen Maneesin Kirjava Sirkus</a></p>
                <p><a href="http://www.sirkussirkus.com/kotiinpaluu/" rel="noopener noreferrer" target="blank">Kotiinpaluu (nykysirkus)</a></p>
                <p></p>
                </div>
                </div>
            },

            show:


            {
                show1: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show1.jpg"></img>

                    <h1 className="tabletitle">Kai Kuutamo Show</h1>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Kai Kuutamo Showta on esitetty kodeissa, kaduilla, häissä, vanhainkodeissa, syntymäpäivillä sekä myös suurissa tapahtumissa kansainvälisesti jo vuodesta 2010. Tällä esityksellä voitettiin sekä vuoden 2012 että vuoden 2024 katuesiintymisen Suomen mestaruus. Vuonna 2020 Kai Kuutamo Show oli myös osana Kansallisteatterin Kiertuenäyttämön ohjelmistoa.</p>

                        <p>Esitys perustuu puheen, improvisaation, komiikan ja näyttävien jongleeraustemppujen yhdistelmään. Vuorovaikutus yleisön kanssa on merkittävässä roolissa, ja siksi esityksestä ovatkin vuosien varrella nauttineet niin lapset kuin senioritkin. Esityksessä nähdään mm. taitavaa jalkapallojen käsittelyä, tennispallojen kiinniottoa korvilla, Pansiolainen pallokatapultti, jongleerausta aina kahdeksaan palloon asti sekä ainutlaatuinen diabolo-onkivapatemppu.</p>    
                            
                        <p>Kai Kuutamo Show sopii mihin tahansa tilaan ja tilaisuuteen, ja se voidaan esittää kolmella eri kielellä (Suomi, Englanti, Khmer) tai tarvittaessa jopa sanattomasti. Esityksen pituuteen voi vaikuttaa, ja sitä voidaan esittää myös useassa osassa juontojen tai isännöinnin yhteydessä.</p>
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                    <p>Kesto: 5-45 minuuttia sopimuksen mukaan.</p>
                    <p>Kohdeyleisö: Kaikenikäiset. Voidaan toteuttaa myös aikuiseen makuun sopimuksen mukaan.</p>
                    <p>Kieli: Suomi, Englanti tai Khmer (Kambodza)</p>
                    <p>Tila: Soveltuu sisälle ja ulos. Tasainen alusta pakollinen. Korkeudesta ja tuulensuojasta etua.</p>
                    <p>(45 minuutin esitykseen minimikorkeus 8 metriä ja lattiatila 8 x 6 metriä.)</p>
                    <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
                    <p>Pystytys 10 minuuttia (30 minuuttia omalla teknisellä kalustolla)</p>
                    <p>Purku 10 minuuttia (20 minuuttia omalla teknisellä kalustolla</p>

                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">

                        <p>"Kai Kuutamo on vakuuttavan karismaattinen esiintyjä. Yhteys yleisöön syntyy hetkessä ja katsomo sulaa helposti nauruun ja aplodeihin."</p>
                        <p>- Turun Sanomat</p>
                        <div className="showbreak2"></div>
                        <p>"Ammattimaista, yleisöä huomioivaa ja viihdyttävää taituruutta jonglööri Kai Kuutamolta."</p>
                        <p>- Olli Lepänrinne / MagnumLive</p>
                        <div className="showbreak2"></div>
                        <p>"Although I am blind I could feel your energy. It was a pleasure to experience your show."</p>
                        <p>- Akim Color, sokea muusikko</p>
                        <div className="showbreak2"></div>
                        <p>"Sä olet paras esiintyjä!"</p>
                        <p>- ala-astelainen</p>
                        <div className="showbreak2"></div>
                        <p>"Sä teet hyvästä itsetunnosta taidetta."</p>
                        <p>- Harri Lukander</p>
                        <div className="showbreak2"></div>
                        <p>"Kiitos esityksestä, joka oli mahtava! Asukkaamme selkeästi nauttivat ja saivat iloa päiväänsä. Olet lämpimästi tervetullut toistekin asukkaita ja henkilökuntaa piristämään."</p>
                        <p>- Helsingin Seniorisäätiö</p>
                        <div className="showbreak2"></div>
                    </div>
                    </div>

                    <div className="moremargin"></div>

                    </div>,

                    show1_pics: ["./pictures5/kaikuutamoshow1.jpg", "./pictures5/kaikuutamoshow2.jpg", "./pictures5/kaikuutamoshow3.jpg", "./pictures5/kaikuutamoshow4.jpg", "./pictures5/kaikuutamoshow5.jpg", "./pictures5/kaikuutamoshow6.jpg", "./pictures5/kaikuutamoshow7.jpg", "./pictures5/kaikuutamoshow8.jpg", "./pictures5/kaikuutamoshow9.jpg"],

    show10: 

    <div>
    
                        <img className="showpic" alt="Show" src="./pictures5/show10.jpg"></img>
    
                        <h1 className="tabletitle">Kai Kuutamon Matkalaukkusirkus</h1>                    
    
                        <div className="tableintro">
                        <div className="tabletext">
                             
    
                            <p>Matkalaukkusirkus on Kai Kuutamon uusi päiväkoti-ikäisille suunnattu kiertävä yhden miehen esitys</p>
    
                            <p>Matkalaukkusirkus on matkalaukkuun mahtuva esitys, joka vie yhden miehen voimin sirkuksen monta jännittävää ulottuvuutta yleisön kuin yleisön luokse. Matkalaukkusirkus on vartavasten suunniteltu sopimaan tilaan kuin tilaan.</p>
    
                            <p>Esityksessä nähdään muun muassa taitavaa korvajongleerausta, vaikeita taikatemppuja, erikoinen pallovipulauta eikä eläinnumeroitakaan ole unohdettu. Näe ratsastava hevonen sekä kuuluisa akrobaattipossu samassa esityksessä! Mutta miten kaikki tämä voi mahtua matkalaukkuun?! Se selviää tietysti Kai Kuutamon Matkalaukkusirkus-esityksessä!</p>
    
                            <p>Sirkustaiteilija Kai Kuutamo juontaa itse koko esityksen, ja vuorovaikutus lapsiyleisön kanssa on tärkeässä roolissa. Esityksessä ei käytetä oikeita eläimiä.</p>
    
                      
                        </div>
                        </div>
    
                        <div className="showbreak"></div>
    
                        <div className="tableintro">
                        <div className="tabletext">
                            <p>Kesto: noin 35 minuuttia.</p>
                            <p>Kohdeyleisö: Päiväkoti-ikäiset ja ala-astelaiset.</p>
                            <p>Tila: Esitys soveltuu millaiseen tilaan vain. Korkeudesta on etua.</p>
                            <p>Kieli: Suomi tai englanti.</p>
                            <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
                            <p>Pystytys 30 minuuttia</p>
                            <p>Purku 20 minuuttia</p>
    
                            
                        </div>
                        </div>
    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>”Kuutamo tietää, miten sirkusta tehdään lapsille. Yleisöä kuunnellaan esityksen aikana ja katusirkukselle tyypillinen rento läsnäolo puree myös aikuisiin.”</p>
                        <p>- Turun Sanomat</p>
                        <div className="showbreak2"></div>
                        <p>"Moi Kai, esityksestä jäi todella hyvä mieli. Siitä välittyi yleisöään kunnioittava ammatillinen ote."</p>
                        <p>- Yt. Maarit Kallioniemi, lastentarhanopettaja, Turku </p>
                        <div className="showbreak2"></div>
                        <p>"Tykkäsin todella paljon, ja lapset olivat aivan haltioissaan. Mukavaa kun otit huomioon myös yleisöstä tulleet lukuisat kommentit."</p>
                        <p>- Päiväkodin Johtaja, Aura</p>
                        <br></br>
                        <p>"Esitys oli jymymenestys niin aikuisten kuin lastenkin mielestä, ja esitys oli myös sopiva kestoltaan."</p>
                        <p>- Yt. Maria Lundén / Taoskujan päiväkodin vanhempainyhdistys, Turku</p>

                        <br></br>
                    </div>
                    </div>
    
                        <div className="showbreak"></div>

                        <div className="tableintro">
                    <div className="tabletext">
                        <p>Voit tutustua Matkalaukkusirkukseen tarkemmin esityksen omilla verkkosivuilla:</p>
                        <p> <a className="showlink" href="http://www.matkalaukkusirkus.com" target="blank">www.matkalaukkusirkus.com</a></p>   
                    </div>
                    </div>
                    


                        <div className="moremargin"></div>

</div>,

show10_pics: ["./pictures5/matkalaukkusirkus1.jpg", "./pictures5/matkalaukkusirkus3.jpg", "./pictures5/matkalaukkusirkus2.jpg",  "./pictures5/matkalaukkusirkus9.jpg","./pictures5/matkalaukkusirkus4.jpg", "./pictures5/matkalaukkusirkus5.jpg", "./pictures5/matkalaukkusirkus6.jpg", "./pictures5/matkalaukkusirkus7.jpg", "./pictures5/matkalaukkusirkus8.jpg"],

                        
show4: 

<div>

    <img className="showpic" alt="Show" src="./pictures5/show4.jpg"></img>

    <h1 className="tabletitle">Prasu</h1>

    <div className="tableintro">
    <div className="tabletext">
        <p>Prasu on murresana, joka tarkoittaa nuotiota, valkeaa ja tulta. Kai Kuutamon yhden miehen tuliesityksessä Prasussa nähdään tulennielentää, ainutlaatuista soihtujongleerausta sekä palavien köysien vauhdikasta pyöritystä. Tyylikäs, jännittävä ja väkevä esitys pitää otteessaan alusta loppuun asti, eikä varmasti jätä ketään kylmäksi! Esitys sopii kaiken ikäisille, ja se toteutetaan musiikkiin sovitettuna. Musiikin esitykseen on säveltänyt suomalainen haitarivirtuoosi Toni Perttula.</p>

        <p>Esityksen soihtujongleerausnumeroa on esitetty jo vuodesta 2004 lähtien mm. Lahden Kaupunginteatterissa, Tampere-talolla, Salpausselän Kisoissa, Turun Linnassa sekä Turun Joulukadun avajaisissa.</p>

        <p>Pidempää kestoa tai lisää näyttävyyttä haluttaessa, esitykseen voidaan yhdistää muita tulitaiteilijoita, pyrotekniikkaa (räjähdykset, kipinäsuihkut ja liekinheittimet) ja/tai ilotulitus.</p>
    </div>
    </div>

    <div className="showbreak"></div>

    <div className="tableintro">
    <div className="tabletext">
        <p>Kesto: 10 minuuttia (suuremmalla ryhmällä 10-25 min)</p>
        <p>Kohdeyleisö: Kaikenikäiset.</p>
        <p>Tila: Yleensä esitetään ulkona. 8x8 metriä tasainen alusta. Mahdollisimman tuulensuojainen paikka. Sisätiloissa tilan on sovelluttava avotulen käyttöön paloturvallisuussääntöjen mukaisesti.</p>
        <p>Kieli: Sanaton / Musiikki</p>
        <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
        <p>Pystytys 40 minuuttia</p>
        <p>Purku 30 minuuttia</p>
    </div>
    </div>
   
    
    <div className="moremargin"></div>

</div>,

show4_pics: ["./pictures5/tuliesitys_prasu1.jpg","./pictures5/tuliesitys_prasu8.jpg", "./pictures5/tuliesitys_prasu7.jpg","./pictures5/tuliesitys_prasu5.jpg", "./pictures5/tuliesitys_prasu3.jpg", "./pictures5/tuliesitys_prasu6.jpg"],


                    
                    show2: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show2.jpg"></img>

                    <h1 className="tabletitle">MERKKARIT JA KAI KUUTAMO</h1>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Lastenmusiikkibändi Merkkarien ja sirkustaiteilija Kai Kuutamon esityksessä yhdistyvät vauhdikas ja omintakeinen lasten rock-musiikki, taitavat sirkustemput sekä hauskat ja osallistavat laululeikit.</p>

                        <p>Esityksessä soivat Merkkareiden omat kappaleet sekä klassikkolastenlaulut. Energinen esitys tempaa yleisön mukaansa, ja tututkin lastenlaulut saavat Merkkarien käsittelyssä aivan uutta virtaa. Kai Kuutamo esittelee kappaleiden säestämänä taitojaan eri jongleerausvälineillä ja johdattelee katsojat mukaan yhteisiin musiikkileikkeihin.</p>

                        <p>MERKKARIT JA KAI KUUTAMO -ryhmän hauskan esityksen parissa viihtyvät helposti myös aikuiset ja nuoriso. Esitys sopii niin suurille festivaaliareenoille ja teatteritiloihin kuin myös pienempiin tilaisuuksiin, päiväkoteihin ja kouluihin.</p>

                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                    <p>Kesto: 20-40 minuuttia sopimuksen mukaan.</p>
                    <p>Kohdeyleisö: Koko perhe.</p>
                    <p>Tila: Soveltuu monenlaisiin tiloihin sekä äänentoiston että jongleerauksen puolesta.</p>
                    <p>Kieli: Suomi, ruotsi tai kumpaakin.</p>
                    <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
                    <p>Pystytys: 60 minuuttia</p>
                    <p>Purku: 30 minuuttia</p>
                        
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Lisätietoa esityksestä ja kokoonpanosta</p> 
                        <p><a className="showlink" href="http://www.sirkussirkus.com/merkkarit/" target="blank">www.merkkarit.com</a></p>
                        
   
                    </div>
                    </div>

                    <div className="moremargin"></div>

                    </div>,

show2_pics: ["./pictures5/merkkaritjakaikuutamo1.jpg", "./pictures5/merkkaritjakaikuutamo2.jpg", "./pictures5/merkkaritjakaikuutamo3.jpg", "./pictures5/merkkaritjakaikuutamo4.jpg", "./pictures5/merkkaritjakaikuutamo8.jpg", "./pictures5/merkkaritjakaikuutamo6.jpg", "./pictures5/merkkaritjakaikuutamo7.jpg", "./pictures5/merkkaritjakaikuutamo9.jpg", "./pictures5/merkkaritjakaikuutamo5.jpg"],


show3: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show3.jpg"></img>

                    <h1 className="tabletitle">Herttakuninkaat</h1>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>"Herttakuninkaiden nimikkoteoksessa Kalle Lehto ja Kai Kuutamo marssittavat yleisön eteen joukon erikoisia hahmoja, jotka kukin esittelevät omaa sirkustaiteen tyylilajiaan aina eläinsirkuksesta nykysirkukseen. Herttakuninkaat lyövät ällikällä koko perheen!"</p>

                        <p>Esityksessä käydään yhdessä yleisön kanssa läpi erilaisia sirkustaiteen tyylilajeja itseironisella ja asiantuntevalla ammattiotteella sekä kerronnan että käytännön kautta. Esitys on ihastuttanut katsojia ympäri Suomea vuodesta 2014 lähtien herskuvalla huumorilla ja taitavilla tempuilla tuoden hymyn mitä vakavimpienkin katsojien huulille. Esitystä on nähty mm. Seikkisrock, Norpas, Skidit sekä SmugsuRock festivaaleilla ja se on kiertänyt yli 60 vanhainkotia Varsinais-Suomessa ja pääkaupunkiseudulla.</p>

                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                    <p>Kesto: 40 min.</p>
                    <p>Kohdeyleisö: Koko perhe.</p>
                    <p>Tila: Sovellettavissa erilaisiin tiloihin. Korkeudesta etua.</p>
                    <p>Mahdollista esittää myös ulkona.</p>
                    <p>Kieli: Suomi tai englanti.</p>
                    <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
                    <p>Pystytys: 45 minuuttia</p>
                    <p>Purku: 30 minuuttia</p>   
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>"Tää oli kyllä kivointa mitä meillä on täällä ikinä ollut!"</p>
                        <p>- Vanhainkodin asukas, Nauvo</p>
                        <div className="showbreak2"></div>

                        <p>"Jopa Pena (nimi muutettu), jolla on 10% näöstä jäljellä, nautti esityksestä."</p>
                        <p>- Vanhainkodin hoitaja, Helsinki</p>
                        <div className="showbreak2"></div>

                        <p>"Mukavaa kun oli huumoria mukana. Koko esityksen ajan hymyilytti."</p>
                        <p>- Vanhainkodin asukas, Turku</p>
                        <div className="showbreak2"></div>

                        <p>"Teette hyvää työtä, pojat. Olette taitavia, lahjakkaita ja iloisia."</p>
                        <p>- Vanhainkodin asukas, Hyvinkää</p>
                        <div className="showbreak2"></div>

                        <p>"Juuri oikean mittainen ja oikeanlainen esitys meidän asukeille."</p>
                        <p>- Vanhainkodin hoitaja, Helsinki</p>
                                                
                        <div className="showbreak"></div>

                    </div>
                    </div>


                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Lisätietoa Herttakuninkaista täältä:</p> 
                        <p><a className="showlink" href="http://www.sirkussirkus.com/herttakuninkaat/" target="blank">www.herttakuninkaat.com</a></p>
                        
   
                    </div>
                    </div>

                       


                    <div className="moremargin"></div>

                    <div className="icont_2">
                    <div className="icont2_2">
                    <iframe className="video" title="1" src="https://www.youtube.com/embed/c0TV9iVjoyY" allowFullScreen></iframe>
                    </div>
                    </div>


                    </div>,
show3_pics: ["./pictures5/herttakuninkaat1.jpg", "./pictures5/herttakuninkaat2.jpg", "./pictures5/herttakuninkaat3.jpg", "./pictures5/herttakuninkaat4.jpg", "./pictures5/herttakuninkaat5.jpg", "./pictures5/herttakuninkaat6.jpg", "./pictures5/herttakuninkaat7.jpg", "./pictures5/herttakuninkaat8.jpg", "./pictures5/herttakuninkaat9.jpg"],
                   

show6: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show6.jpg"></img>

                    <h1 className="tabletitle">Rainhard "Pitkä Rane" Reisiheisi & Pitkät Hemmot</h1>
                                        
                    

                    <div className="tableintro">
                    <div className="tabletext">
                         

                        <p> Yllä tapahtumasi kanssa uusiin korkeuksiin Pitkä Ranen ja kumppanien avulla! Rainhard Reisiheisi tunnetaan kaverien kesken nimellä Pitkä Rane, ja kavereitahan Ranella riittää minne hän ikinä eksyykään! Pitkällä mitallaan hän tulee helposti huomatuksi, ja iloisen ulospäinsuuntautuneen luonteensa ansiosta hänen kanssaan on helppo jutella päivän polttavista aiheista, räätälöidyistä vaatteista tai terveellisestä ruokavaliosta.</p>

                        <p>Pitkä Ranella ei ole varsinaista omaa esitystä, vaan hänet voi tilata kiertelemään tapahtumiin, ja ottamaan kontaktia kävijöihin tai muuten vaan olemaan pitkänä. Lentolehtisten tai esimerkiksi ilmapallojen jakaminen onnistuu myös (tilaaja vastaa materiaaleista).</p>

                        <p>Suurempiin tapahtumiin voit myös tilata 2-5 hengen pitkän seurueen, sillä Rainhard Reisiheisi tuntee pitkien piirit! Usein Pitkä Rane nähdäänkin käyskentelemässä yhdessä hyvän ystävänsä Harri Harha-Askeleen kanssa (kaverikuva alempana).</p>
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Kesto: Pisin mitta yhdelle kävelylle yhtäjaksoisesti on 1 tunti. Pidemmissä rupeamissa yhtä kävelyä kohti 45 minuuttia lepoa, ellei toisin sovita.</p>
                        <p>Kieli: Suomi, Englanti, Khmer (Kambodza), auttava Ruotsi, haittaava Espanja</p>
                        <p>Tila: Missä tahansa, kunhan maasto on suhteellisen tasaista.</p>
                        <p>Erityistarpeet: Lukittava takalava, jossa 150 cm korkea tukeva alusta pukemista varten.</p>
                    </div>
                    </div>

                    <div className="moremargin"></div>


                    </div>,

                    show6_pics: ["./pictures5/puujalkahahmot1.jpg", "./pictures5/puujalkahahmot2.jpg", "./pictures5/puujalkahahmot3.jpg", "./pictures5/puujalkahahmot4.jpg", "./pictures5/puujalkahahmot5.jpg", "./pictures5/puujalkahahmot6.jpg", "./pictures5/puujalkahahmot7.jpg", "./pictures5/puujalkahahmot8.jpg", "./pictures5/puujalkahahmot9.jpg"],


show8: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show8.jpg"></img>

                    <h1 className="tabletitle">Sirkuskunnan Narrit</h1>
                                        
                    

                    <div className="tableintro">
                    <div className="tabletext">
                         

                        <p>Sirkuskunnan Narrit tuovat keskiajan hengen ja hulvattoman meiningin luoksesi leikarimaisella katuesityksellään, joka esittelee yleisölleen aikansa ihmeitä kuten kapuloiden käsittelyä, käsillä tasapainoilua, tynnärin vanteiden pyöritystä, mahtityrväällä isottelua sekä KUOLEMANTEMPPUJA! Koko esitys on aina läpeensä juonnettu ja täynnä hullunkurista, mutta eteenpäin virtaavaa puhtia.</p>

                        <p>Turun Keskiaikaisilta Markkinoilta ponnistaneet Sirkuskunnan Narrit ovat nauttineet vuosien varrella suurenmoista suosiota. He ovat esiintyneet Turun Keskiaikaisilla markkinoilla jo yli 10 vuoden ajan, ja he ovat vierailleet jo kaikissa Suomen suurissa keskiaikatapahtumissa. Sirkuskunnan Narrit ovat myös tavallinen näky Turun Linann tapahtumissa ja he ovat esiintyneet jopa linnan kuninkaan salissa!</p>

                        <p>Narreja on mahdollista palkata esiintymään 1-5 hengen ryhmissä, jossa jokaisella narrilla on aina muista poikkeava oma erikoistaitonsa. Pisimpään remmissä ovat matkustaneet narrit Urho, Suikki, Yrvänä ja Inkeri. Sirkuskunnan Narrien riveissä ovat vuosien varrella loistaneet myös narrit Elsa, Seikka, Elmeri, Riski-Jooseppi, Iita ja Ongervo.</p>

                        <p>Mutta ketä nämä narrit sitten ovat? Kaikki esiintyjät ovat sirkustaiteen ammattilaisia ja kokeneita esiintyjiä. Narrinimien takaa löytyvät Kai Kuutamo, Tuomas Vuorinen, Jukka Grönfors, Susanna Liinamaa, Heidi Aho, Petra Niskanen, Teemu Skön, Tiina Tuomisto, Pekka Laamanen ja Elina Vessonen.</p>

                        
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Kesto: 10-40 min. riippuen toiveista ja esiintyjien määrästä.</p>
                        <p>Kohdeyleisö: Koko perhe. Voidaan myös suunnata tilaisuuden luonteen mukaan aikuisille.</p>
                        <p>Kieli: Suomi tai Englanti</p>
                        <p>Tila: Missä tahansa. Tasainen alusta ja tuulensuoja eduksi. Yleensä esitetty keskiaikatapahtumissa kadulla tai nurmikolla.</p>
                        <p>Pystytys: 10 minuuttia</p>
                        <p>Purku: 10 minuuttia</p>


                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>"Juuri tällaista huumoria pitää täällä olla!"</p>
                        <p>- Mies Turun Keskiaikaisilla Markkinoilla</p>

                        <div className="showbreak2"></div>

                        <p>"Markkinoiden paras ja hauskin esitys. Ehdottomasti!"</p>
                        <p>- Nainen Turun Keskiaikaisilla Markkinoilla</p>
                        
                    </div>
                    </div>

                    <div className="moremargin"></div>


                    </div>,

                    show8_pics: ["./pictures5/narrit1.jpg", "./pictures5/narrit3.jpg", "./pictures5/narrit4.jpg", "./pictures5/narrit5.jpg", "./pictures5/narrit6.jpg", "./pictures5/narrit8.jpg", "./pictures5/narrit7.jpg", "./pictures5/leikarit.jpg", "./pictures5/urhonarri.jpg", "./pictures5/narrit11.jpg", "./pictures5/lerssitorni.jpg", "./pictures5/narrit33.jpg"],



                

            },
            

            articles: 

            <div>
            <div className="tabletitlewrap">
            <h1 className="tabletitle">Lehtiartikkelit ja muu media</h1>
     
            </div>

            <div className="tableintro">
            <div className="tabletext">
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Aamuset 25.7.2024</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.sirkussirkus.com/artikkelit/2024-aamuset.jpg" target="_blank"><p>Kai Kuutamo voitti jälleen katuesiintymisen Suomen mestaruuden
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Laitilan Sanomat 11.6.2024</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.sirkussirkus.com/artikkelit/2024-laitilansanomat.jpg" target="_blank"><p>Kuutamo luovuttaisi mielellään legendaarisimman keikkamuiston Munamarkkinoille
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 10.6.2024</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.sirkussirkus.com/artikkelit/2024-turunsanomat-1.jpg" target="_blank"><p>Pansion Sirkus tuo iloa ja eloa lähiöön
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Aamuset 30.8.2023</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.sirkussirkus.com/artikkelit/2023-aamuset-2.jpg" target="_blank"><p>Into diabolosta ponnahti uraksi
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turku Posti 3/2023</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.sirkussirkus.com/artikkelit/2023-turkuposti-1.jpg" target="_blank"><p>Kirjastoautokiertue tuo taide-esityksiä kirjastoauton pysäkeille
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 14.8.2023</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.sirkussirkus.com/artikkelit/2023-turunsanomat-2.jpg" target="_blank"><p>Turku on mahtava sirkuskaupunki
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Åbo Underrättelser 12.8.2023</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://abounderrattelser.fi/cirkusartister-fran-hela-varlden-intar-scenen-i-abo/" target="_blank"><p>Cirkusartister från hela världen intar scenen i Åbo</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Aamuset 29.7.2023</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://aamuset.fi/artikkeli/6058739" target="_blank"><p>Maksuton katusirkusfestivaali Vanhalla Suurtorilla
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 29.7.2023</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.sirkussirkus.com/artikkelit/2023-turunsanomat-1.jpg" target="_blank"><p>Katusirkusfestivaali valtaa Vanhan Suurtorin elokuussa
            </p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Ilkka Pohjalainen 20.5.2023</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://ilkkapohjalainen.fi/ihmiset-ja-kulttuuri/pikku-provinssi-avasi-festarikesän-yleisö-innostui-päristimistä-ja-jongleerauksesta" target="_blank"><p>Kai Kuutamo Show keräsi Törnävänsaaren ulkolavan edustalle pikkukatsojia</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Vakka-Suomen Sanomat 16.6.2022</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2022-vakkasuomensanomat.jpg" target="_blank"><p>Kesä on maalla - Askaisten lastentapahtumassa kukki sirkustaide ja huumori</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 28.11.2020</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://content.ts.fi/5131540?articleid=5131540&headline=Olipa+kerran+koditon+sirkustaiteilija...+%E2%80%93+Suomen+ainoan+sirkuskaupan+tarina+on+huikea+sattumusten+summa#1349" target="_blank"><p>Olipa kerran koditon sirkustaiteilija... (digitaalinen multimediajulkaisu)</p></a>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2020-turunsanomat-2.jpg" target="_blank"><p>Taidealalla yrittäjyys on yhä tabu (lehtiartikkeli)</p></a>

            <div className="articlebreak"></div>


            <p><span className="articlenewspaper">Oriveden Sanomat 25.9.2020</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2020-orivedensanomat.jpg" target="_blank"><p>Kahden residenssiviikon aikana syntyy uusi täyspitkä sirkusesitys</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Yle Areena 22.7.2020</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://areena.yle.fi/1-50590251" target="_blank"><p>Kansallisteatterin Kiertuenäyttämön Sisäpihasirkus tien päällä</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 9.7.2020</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2020-turunsanomat-1.jpg" target="_blank"><p>Intohimona Jongleeraus </p></a>

            <div className="articlebreak"></div>
            
            <p><span className="articlenewspaper">Aamuset 19.11.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-aamuset.jpg" target="_blank"><p>Merkkarit & Kai Kuutamo juhlakeikalla Vimmassa</p></a>

            <div className="articlebreak"></div>
            
            <p><span className="articlenewspaper">Aamuset 23.10.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-aamuset-2.jpg" target="_blank"><p>Lonttinen sai uuden seinämaalauksen</p></a>
        
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 29.9.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-turunsanomat-3.jpg" target="_blank"><p>Sirkusarvio: Katusirkuksen rentous kohtaa lastensirkuksen kirjavuuden jonglööri Kai Kuutamon kiertue-esityksessä</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Seutusanomat 25.6.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://turunseutusanomat.fi/2019/06/harvaluodon-rannalla-keskikesaa-juhlisti-arviolta-yli-600-ihmista/?fbclid=IwAR3UfAptx0jmS5qV1JKButKJ2Wn79-axxwt-YhveYTjpLPvq_r6lkkjnrRc" target="_blank"><p>Harvaluodon rannalla keskikesää juhlisti arviolta yli 600 ihmistä</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 2019 kesäkuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-turunsanomat-2.jpg" target="_blank"><p>Seikkisrockin yhdistämät</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 13.4.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-turunsanomat-1.jpg" target="_blank"><p>Taiturimaisia temppuja ja vuorovaikutusta</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Aamuset 14.2.2018</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2018-aamuset.jpg" target="_blank"><p>Sirkusta Tunteella - Sirkusputiikin kauppias haluaa vähentää kuluttamista</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 2017 Lokakuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turunsanomat-3.jpg" target="_blank"><p>Kohti Loveen Lankeamista</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 2017 Syyskuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turunsanomat-2.jpg" target="_blank"><p>Pohjolan Äijä saapuu Metsäkirkkoon</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 28.5.2017</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turunsanomat-1.jpg" target="_blank"><p>Rokkimusaa ja jongleerausta</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Tienoo 13.4.2017</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turuntienoo.jpg" target="_blank"><p>Merimiesrock sekoitti Ruskotalon nuoren yleisön</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Auran Aallot 2015</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.auranaallot.fi/uutiset/paikallinen/festivaali-joka-juhlii-kaikkea-samanaikaisesti-monisyinen-festivaali?fbclid=IwAR3V4Je-MOtEchTinkZbd_mXRbYcl3ZbiMA5aP2obNpmwIKw6K6PZTMtg2c" target="_blank"><p>Festivaali, joka juhlii kaikkea samanaikaisesti on monisyinen festivaali</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 18.7.2015</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.ts.fi/uutiset/paikalliset/796198/Sirkustaiteilija+Kai+Kuutamo+rikkoi+jongleerauksen+Suomen+ennatyksen" target="_blank"><p>Sirkustaiteilija Kai Kuutamo rikkoi jongleerauksen Suomen ennätyksen</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Hoitokoti Päiväkummun Sanomat 1/2015</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2015-paivakummunsanomat.jpg" target="_blank"><p>Sirkushuveja, taikatemppuja ja akrobatiaa hoitokodeissa</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomien Extra-liite 2014</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2014-tsextra.jpg" target="_blank"><p>Kai Kuutamo - Väärinymmärretty maun lisääjä</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Fidea Suomen Draama- ja Teatteriopetuksen liiton jäsenlehti 2014</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2014-fidea.jpg" target="_blank"><p>Circus Kampot - Suomalainen sirkuskoulu Kambodzassa</p></a>
         
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 6.8.2013</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.ts.fi/kulttuuri/519198/Rytmikasta+jongleerausta" target="_blank"><p>Rytmikästä Jongleerausta</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Aamuset 2012 heinäkuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2012-aamuset.jpg" target="_blank"><p>Elämä yhtä sirkusta</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 19.8.2012</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.ts.fi/kulttuuri/380163/Maailma+on+erilainen+sirkustaiteilijan+silmin" target="_blank"><p>Maailma on erilainen sirkustaiteilijan silmin</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 2012 elokuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2012-turunsanomat.jpg" target="_blank"><p>Kuutamo tarjoaa logistiikkaa ja sirkushuveja</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">TS-TV 13.8.2012</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://tstv.fi/video/2699" target="_blank"><p>Sirkustaiteilija pahvilaatikoiden keskellä</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 22.1.2010</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2010-turunsanomat.jpg" target="_blank"><p>Ihan Jonglöörinä</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 19.7.2008</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.ts.fi/viihde/1074295603/Lapsille+raataloity+sirkus+taituroi+Seikkispuistossa" target="_blank"><p>Lapsille räätälöity sirkus taituroi Seikkailupuistossa</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 27.2.2007</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.ts.fi/viihde/1074183475/Kun+jongloori+palaa+kotiinsa" target="_blank"><p>Kun jonglööri palaa kotiinsa</p></a>

            <div className="articlebreak"></div>


            </div>
            </div>

            </div>,

            contact:
            
            <div className="tabletitlewrap">
            <h1 style={{textAlign: "center"}} className="tabletitle">Yhteystiedot ja laskutus</h1>
            <div className="tableintro">
            <div className="tabletext contacttext">

            <center>
            <div className="white-line2"></div>
            </center>

            <p id="contacttitle2">Jonglööri Kai Kuutamo</p>
            <p>kai@sirkussirkus.com</p>
            <p>puh. 040 754 6182</p>
            <p>Turku</p>

            <div className="white-line2"></div>            
            </div>
            </div>

            <div className="tableintro">
            <div className="tabletext contacttext">

            <p id="contacttitle2">Tippi, lahjoitukset ja sosiaalinen media</p>
            <p><a className="articlelink" href="https://sirkussirkus.com/tip/" rel="noopener noreferrer" target="of">Kai Kuutamo Tip & Donate</a></p>
            <p><a className="articlelink" href="https://onlyfans.com/kaikuutamo" rel="noopener noreferrer" target="of">Kai Kuutamo OnlyFans</a></p>
            <p><a className="articlelink" href="https://www.instagram.com/kaikuutamo/" rel="noopener noreferrer" target="of">Kai Kuutamo Instagram</a></p>
            <p><a className="articlelink" href="https://www.facebook.com/kuutamosirkus/" rel="noopener noreferrer" target="of">Kai Kuutamo & Sirkussirkus Facebook</a></p>
            <p><a className="articlelink" href="https://www.tiktok.com/@kaikuutamo" rel="noopener noreferrer" target="of">Kai Kuutamo TikTok</a></p>
            <p><a className="articlelink" href="https://www.linkedin.com/in/kaj-mikael-sch%C3%BCtt-28b0ba28/" rel="noopener noreferrer" target="of">Kai Kuutamo LinkedIn</a></p>
            <p><a className="articlelink" href="https://g.page/r/CZ6fN9ytpfUYEAI/review" rel="noopener noreferrer" target="of">Sirkussirkus Google Review</a></p>

            <div className="white-line2"></div>
            </div>
            </div>

            <div className="tableintro">
            <div className="tabletext contacttext">

            <p id="contacttitle2">Laskutus ja posti</p>
            <p>Sirkussirkus</p>
            <p>Kaj-Mikael Schütt</p>
            <p>Y-tunnus: 2003842-8</p>
            <p>VAT: FI20038428</p>
            <p>Laivateollisuudenkatu 28 A 20</p>
            <p>20240 Turku</p>
            <p>FINLAND</p>

            <div className="white-line2"></div>
            </div>
            </div>

            <div className="tableintro">
            <div className="tabletext contacttext">
            
            <p id="contacttitle3">Verkkolaskut</p>
            <p>Verkkolaskuosoite 003720038428 (MAVENTA)</p>
            <p>Välittäjätunnus 003721291126</p>
            <p>Välittäjätunnus pankkiverkossa DABAFIHH</p>

            <div className="white-line2"></div>
            </div>
            </div>


            </div>,


form:

<div>

<div className="tabletitlewrap">
        <h1 style={{textAlign: "center"}} className="tabletitle">Kiitos!</h1>
        
</div>

<p id="thankyou">Tarjouspyyntösi on lähetetty!</p>

</div>,

promo:

<div>
<div className="tableintro">
<div className="tabletext">
<p id="promopicstext">PROMOOTIOKUVAT</p>


<div id="promopics">

<center>

<p>(klikkaa suureksi ennen latausta)</p>
<div className="white-line"></div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-0.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo0.jpg" alt="Kai Kuutamo Promokuva 0"></img></a>
<p>Valokuvaaja: Matias Silmunen</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-1.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo1.jpg" alt="Kai Kuutamo Promokuva 1"></img></a>
<p>Valokuvaaja: Matias Silmunen</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-2.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo2.jpg" alt="Kai Kuutamo Promokuva 2"></img></a>
<p>Valokuvaaja: Ville Saarikoski</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-3.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo3.jpg" alt="Kai Kuutamo Promokuva 3"></img></a>
<p>Valokuvaaja: Anna Schütt</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-4.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo4.jpg" alt="Kai Kuutamo Promokuva 4"></img></a>
<p>Valokuvaaja: Matias Silmunen</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-5.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo5.jpg" alt="Kai Kuutamo Promokuva 5"></img></a>
<p>Valokuvaaja: Rauli Katajavuori</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-6.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo6.jpg" alt="Kai Kuutamo Promokuva 6"></img></a>
<p>Valokuvaaja: Flashing Porridge</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-7.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo7.jpg" alt="Kai Kuutamo Promokuva 7"></img></a>
<p>Valokuvaaja: Matias Silmunen</p>
<div className="white-line"></div>
</div>


<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-9.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo9.jpg" alt="Kai Kuutamo Promokuva 9"></img></a>
<p>Valokuvaaja: Pokko Lemminkäinen</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-11.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo11.jpg" alt="Kai Kuutamo Promokuva 11"></img></a>
<p>Valokuvaaja: Chantrea Yun</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-12.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo12.jpg" alt="Kai Kuutamo Promokuva 12"></img></a>
<p>Valokuvaaja: Vanja Fransson</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-13.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo13.jpg" alt="Kai Kuutamo Promokuva 13"></img></a>
<p>Valokuvaaja: Eero Saarikoski</p>
<div className="white-line"></div>
</div>


<p></p>

</center>

</div>
</div>
</div>
</div>

}


export default finnish;